import logo from './logo.svg';
import './App.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Accordion, ListGroup, Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// {
//   "Accordion": {
//     "Container": {
//       "Row": {
//         "Col": {
//           "ListGroup": {
//             "ListItem": {
              
//             }
//           }
//         }
//       }
//     }
//   }
// }


function App() {
  return (
    <div className="App">
      <Accordion defaultActiveKey="0" alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Kinetix</Accordion.Header>
          <Accordion.Body>
            <Container>
              <Row>
                <Col>
                  <ListGroup as="ul" numbered>
                    <ListGroup.Item as="li" active>Kinetix</ListGroup.Item>
                    <ListGroup.Item as="li" action><a href="https://kinetixsystems.sharepoint.com/sites/MPFA-NotestoSharePoint/Lists/BCPTasks/" target="_blank" rel="noreferrer">BCPTasks</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://kinetixsystems.sharepoint.com/sites/MPFA-NotestoSharePoint/Lists/DSETasks/" target="_blank" rel="noreferrer">DSETasks</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://kinetixsystems.sharepoint.com/:x:/r/sites/HAPFSIAM/_layouts/15/Doc.aspx?sourcedoc=%7B1F9E6285-2BD7-41C2-8398-C4DC0625FD79%7D&amp;file=TD_IssueLog.xlsx" target="_blank" rel="noreferrer">IAM+ TD_IssueLog</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://kinetixsystems.sharepoint.com" target="_blank" rel="noreferrer">Sharepoint</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://kinetixsystems.sharepoint.com/:x:/r/_layouts/15/Doc.aspx?sourcedoc=%7BEF18BE29-2DA5-48DD-BB94-7C33E4CE8B9E%7D&amp;file=Staff Contact List.xlsx&amp;action=default&amp;mobileredirect=true" target="_blank" rel="noreferrer">Staff Contact List</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://ksl.crm5.dynamics.com/" target="_blank" rel="noreferrer">Time entries</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://ess2.echr.com.hk/kinetix/" target="_blank" rel="noreferrer">ecHR</a></ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup as="ul">
                    <ListGroup.Item as="li" active>MPFA Sharepoint</ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://mpfa-sharepoint/bcp" target="_blank" rel="noreferrer">BCP</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://mpfa-sharepoint/sites/appcats" target="_blank" rel="noreferrer">MPFA -  AppCatalog</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://mpfa-sharepoint/mpfa/bcp/" target="_blank" rel="noreferrer">MPFA - BCP</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://mpfa-sharepoint/mpfa/dse" target="_blank" rel="noreferrer">MPFA - DSE</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://udi-sharepoint/sites/AppCatalog" target="_blank" rel="noreferrer">UDI - AppCatalog</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://udi-sharepoint/sites/mpfa" target="_blank" rel="noreferrer">UDI - BCP</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://udi-sharepoint/sites/mpfa/dse" target="_blank" rel="noreferrer">UDI - DSE</a></ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup as="ul">
                    <ListGroup.Item as="li" active>HKICL</ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hkicl1.firmstudio.net/eng/" target="_blank" rel="noreferrer">Dev Live</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hkicl1-cms.firmstudio.net/" target="_blank" rel="noreferrer">Dev Live CMS</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hkicl1.firmstudio.net/eng/information_centre/ebpp/ebpp-form.php" target="_blank" rel="noreferrer">Dev Live EBPP</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hkicl1.firmstudio.net/eng/information_centre/regional_chats/download.php" target="_blank" rel="noreferrer">Dev Live RCPS - Download</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hkicl1.firmstudio.net/eng/information_centre/regional_chats/search_engine.php" target="_blank" rel="noreferrer">Dev Live RCPS - Search</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://www.hkicl.com.hk/eng/" target="_blank" rel="noreferrer">Production</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://www.hkicl.com.hk/eng/information_centre/ebpp/ebpp-form.php" target="_blank" rel="noreferrer">Production EBPP</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://www.hkicl.com.hk/eng/information_centre/regional_chats/download.php" target="_blank" rel="noreferrer">Production RCPS - Download</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://www.hkicl.com.hk/eng/information_centre/regional_chats/search_engine.php" target="_blank" rel="noreferrer">Production RCPS - Search</a></ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup as="ul">
                    <ListGroup.Item as="li" active>HAPFS</ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hapfs-iamplus2.home" target="_blank" rel="noreferrer">IAM+ 443</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hapfs-iamplus2.home:9090" target="_blank" rel="noreferrer">IAM+ 9090</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="https://hapfs-iamplus2.home:9091" target="_blank" rel="noreferrer">IAM+ 9091</a></ListGroup.Item>
                    <ListGroup.Item as="li"><a href="http://hoiamplus16/reports/browse/IAM" target="_blank" rel="noreferrer">Reporting Services</a></ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Accordion Item #2</Accordion.Header>
          <Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" >
              <Tab eventKey="home" title="Home">
                <Container>
                  <Row>
                    <Col>
                      <ListGroup>
                        <ListGroup.Item active>Kinetix</ListGroup.Item>
                        <ListGroup.Item><a href="https://kinetixsystems.sharepoint.com/sites/MPFA-NotestoSharePoint/Lists/BCPTasks/" target="_blank" rel="noreferrer">BCPTasks</a></ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col>
                      <ListGroup>
                        <ListGroup.Item active>MPFA Sharepoint</ListGroup.Item>
                        <ListGroup.Item><a href="http://mpfa-sharepoint/bcp" target="_blank" rel="noreferrer">BCP</a></ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col>
                      <ListGroup>
                        <ListGroup.Item active>HKICL</ListGroup.Item>
                        <ListGroup.Item><a href="https://hkicl1.firmstudio.net/eng/" target="_blank" rel="noreferrer">Dev Live</a></ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col>
                      <ListGroup>
                        <ListGroup.Item active>HAPFS</ListGroup.Item>
                        <ListGroup.Item><a href="https://hapfs-iamplus2.home" target="_blank" rel="noreferrer">IAM+ 443</a></ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="profile" title="Profile">
                Tab content for Profile
              </Tab>
              <Tab eventKey="contact" title="Contact">
                Tab content for Contact
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Accordion Item #2</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Accordion Item #2</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Accordion Item #2</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <header className="App-header">
        

        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>

      </header>
    </div>
  );
}

export default App;
